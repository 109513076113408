import React from 'react';
import './Schedule.css';

const Schedule = () => (
  <div className="schedule">
    <h2>Horário de Partida e Chegada</h2>
    {/* Placeholder for the schedule */}
  </div>
);

export default Schedule;
