import React from 'react';
import './Support.css';

const Support = () => {
  return (
    <div className="support">
      <h1>SAC</h1>
      <p>Para dúvidas, reclamações ou sugestões, entre em contato conosco através do nosso SAC...</p>
    </div>
  );
};

export default Support;
