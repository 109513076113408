import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about">
      <h1>Sobre Nós</h1>
      <p>A Fast Buss é uma empresa dedicada a fornecer as melhores opções de transporte público...</p>
    </div>
  );
};

export default About;
