import React from 'react';
import './AddBalance.css';

const AddBalance = () => (
  <div className="add-balance">
    <h2>Adicionar Saldo</h2>
    <button>Gerar QR Code</button>
  </div>
);

export default AddBalance;
